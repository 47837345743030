.eventDateWrapper {
  display: flex;
  position: absolute;
}

.eventDate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 42px;
  padding-left: 12px;
  padding-right: 12px;
  position: absolute;
  top: 0px;
}

.first {
  margin-top: -31px;
  height: calc(100% - 24px);
}

.point {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: var(--light-color);
  position: absolute;
  left: 50px;
  top: 24px;
}