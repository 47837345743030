.root {
  display: flex;
  flex: 1;
}

.mobileWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.mobileWrapper::-webkit-scrollbar {
  display: none;
}

.block {
  border-radius: 10px;
  padding-top: 10px;
}

@media (max-width: 912px) {
  .block {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    box-shadow: none;
    margin: 0;
  }

  .mobileWrapper {
    overflow: auto;
  }
}

@media (max-width: 540px) {
  .root {
    overflow: hidden;
  }
}
