.calendarHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  position: relative;
}

.calendarFilter {
  display: flex;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
  width: 340px;
  padding: 8px 16px;
  border-radius: 8px;
}

.calendarFilter:hover {
  background-color: var(--gray-color);
}

.checked {
  background-color: var(--gray-color);
  z-index: 100;
}

.open {
  rotate: 180deg;
}

.calendarIcon {
  width: 20px;
  height: 20px;
  fill: #eff7ff;
}

.settings {
  cursor: pointer;
}

.settings:hover {
  opacity: 0.75;
}

.settings:active {
  opacity: 0.5;
}

.calendarFilterTitle {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
}

.cover {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--dark-color);
  opacity: 0.75;
  top: 0;
  left: 0;
  z-index: 50;
}

@media (max-width: 912px) {
  .calendarFilterTitle {
    font-size: 16px;
  }

  .header_hidden {
    margin-bottom: -36px;
    overflow: hidden;
    transform: translateY(-120%);
    transition:
      max-height 0.3s 0.3s ease-in-out,
      transform 0.2s ease-in-out;
    -webkit-transform: translateY(-120%);
    -moz-transform: translateY(-120%);
    -ms-transform: translateY(-120%);
    -o-transform: translateY(-120%);
  }

  .header_visible {
    transform: translateY(0);
    max-height: none;
    transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -webkit-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -moz-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -ms-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -o-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
}

@media (max-width: 540px) {
  .calendarHeader {
    padding: 0;
    padding-top: 6px;
  }

  .calendarFilter {
    width: 212px;
  }

  .calendarFilterTitle {
    font-size: 14px;
  }
}
