.contactPage {
  flex-direction: column-reverse !important;
}

.descriptionWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.description {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  display: block;
}

.block {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.formItem {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.label {
  font-size: 20px;
  line-height: 20px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.input {
  border: 2px solid var(--white-color);
  border-radius: 8px;
  padding: 12px 18px;
  background: transparent;
  color: var(--white-color);
  font-size: 18px;
  line-height: 20px;
  resize: none;
}

.input::placeholder {
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  color: rgba(239, 247, 255, 0.5);
  font-family: 'Montserrat', sans-serif;
}

.button {
  color: var(--dark-color);
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  background: var(--light-color);
  padding: 12px 32px;
  border: none;
  border-radius: 10px;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;
}

.button:hover {
  opacity: 0.75;
}

.button:active {
  opacity: 0.5;
}

.link {
  color: var(--light-color);
}

@media (max-width: 912px) {
  .contactPage {
    flex-direction: column-reverse !important;
  }

  .description {
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  .description {
    font-size: 16px;
  }
}