.coverNavigation {
  display: none;
  margin-top: 10vh;
}

@media (max-width: 540px) {
  .coverNavigation {
    background-color: var(--gray-color);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 12px 26px;
    align-items: center;
    position: fixed;
    z-index: 50;
    margin-top: 10vh;
  }
  
  .coverNavLink {
    color: var(--white-color);
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    font-family: 'Verdana-Bold', sans-serif;
    padding: 24px;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid var(--dark-color);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
}