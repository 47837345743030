.dateWeatherPanel {
  font-size: 18px;
  line-height: 22px;
  font-weight: 400;
  cursor: default;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 24px;
}

.weatherIcon {
  display: inline-flex;
  width: 28px;
  height: 28px;
  padding: 0px 0.583px 0.583px 0px;
  justify-content: center;
  align-items: center;
  top: 8px;
  position: relative;
}

.weatherWrapper {
  margin-left: 5px;
}

@media (max-width: 912px) {
  .dateWeatherPanel {
    padding-left: 100px;
    padding-right: 100px;
    background-color: var(--dark-color);
  }
  .header_hidden {
    max-height: 0;
    overflow: hidden;
    transform: translateY(-100%);
    transition:
      max-height 0.1s ease-in-out,
      transform 0.3s ease-in-out;
  }

  .header_visible {
    transform: translateY(0);
    max-height: none;
    transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -webkit-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -moz-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -ms-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
    -o-transition:
      max-height 0.3s ease-in-out,
      transform 0.3s ease-in-out;
  }
}

@media (max-width: 540px) {
  .dateWeatherPanel {
    font-size: 14px;
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 12px;
  }
}
