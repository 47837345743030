.root {
  background-color: var(--gray-color);
  position: absolute;
  top: 56px;
  z-index: 100;
  border-radius: 6px;
  max-height: 500px;
  width: 456px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.calendarCategoriesWrapper {
  width: 100%;
  display: flex;
  overflow: hidden;
  max-height: 300px;
}

.selectAllPanel {
  display: flex;
  justify-content: flex-end;
  padding: 6px;
  padding-left: 16px;
  padding-right: 16px;
  gap: 12px;
}

.selectAllInput {
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  line-height: 14px;
}

.selectAllCheckbox {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border: 1px solid var(--white-color);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.calendarCategories {
  width: 100%;
  overflow: auto;
  padding-bottom: 64px;
}

.calendarCategoryList {
  padding-left: 32px;
}

.calendarCategories::-webkit-scrollbar {
  display: none;
}

.dropdownCategory {
  display: flex;
  flex-direction: column;
}

.dropdownCategoryLabel {
  display: flex;
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
}

.dropdownCategoryLabel:hover {
  opacity: 0.75;
}

.dropdownCategoryLabel:active {
  opacity: 0.5;
}

.calendarCategory {
  padding: 10px 21px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
}

.calendarCategory img {
  position: absolute;
  right: 20px;
}

.calendarCategoryCheckbox {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
  border: 1px solid var(--white-color);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checked {
  background-color: var(--light-color);
  border: 1px solid var(--light-color);
}

.checked::before {
  content: '✔';
  font-size: 10px;
  line-height: 10px;
  color: var(--white-color);
}

.calendarCategoryText {
  display: flex;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 16px;
  gap: 56px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: var(--gray-color);
}

.button {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding-top: 6px;
  padding-bottom: 6px;
  align-items: center;
  width: 80px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
}

.button:hover {
  opacity: 0.75;
}

.cancelButton {
  background-color: inherit;
  color: var(--white-color);
  border: 1px solid var(--white-color);
  border-radius: 6px;
}

.saveButton {
  background-color: var(--light-color);
  color: var(--dark-color);
  border: none;
  border-radius: 6px;
}

.open {
  rotate: 180deg;
}

@media (max-width: 912px) {
  .root {
    max-width: 464px;
    max-height: 662px;
  }

  .buttons {
    gap: 24px;
  }

  .button {
    font-size: 12px;
    line-height: 14px;
    width: 60px;
  }
}

@media (max-width: 540px) {
  .root {
    max-width: 248px;
    max-height: 332px;
  }

  .calendarCategoryList {
    padding-left: 8px;
  }

  .calendarCategory {
    padding: 4px 16px;
  }

  .calendarCategoryText {
    font-size: 14px;
  }

  .calendarCategory img {
    right: 12px;
    top: 18px;
  }
}