/* Montserrat */
@font-face {
  font-family: 'Montserrat';
  src: url('../../../public/fonts/Montserrat-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../../public//fonts//Montserrat-Bold.ttf');
  font-weight: bold;
}

/* Verdana */
@font-face {
  font-family: 'Verdana';
  src: url('../../../public/fonts/Verdana.woff2') format('woff2');
}

/* Verdana-Bold */
@font-face {
  font-family: 'Verdana-Bold';
  src: url('../../../public/fonts/Verdana-Bold.woff2') format('woff2');
}

/* Bowlby */
@font-face {
  font-family: 'Bowlby';
  src: url('../../../public/fonts/Bowlby.woff2') format('woff2');
}

:root {
  --gray-color: #333E53;
  --dark-color: #212C41;
  --white-color: #EFF7FF;
  --light-color: #F4D35E;
  --primary-color: #93827F;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root  {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}

body {
  color: var(--white-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea:focus, input:focus{
  outline: none;
}

a {
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  opacity: 0.75;
}

a:active {
  opacity: 0.5;
}

button, input[type="submit"], input[type="reset"] {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}