.eventsWrapper {
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.eventsWrapper::-webkit-scrollbar {
  display: none;
}

.dateGroup {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 30px;
}

.dateGroup::after {
  content: '';
  position: absolute;
  left: 84px;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 2px;
  background-color: var(--light-color);
}
html {
  scroll-behavior: smooth;
}

.stickyHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 8px;
  transition: transform 0.3s ease;
}

.relativeHeader {
  position: relative;
  transition: position 0.6 ease;
  -webkit-transition: position 0.6 ease;
  -moz-transition: position 0.6 ease;
  -ms-transition: position 0.6 ease;
  -o-transition: position 0.6 ease;
}

.unsticky {
  opacity: 0;
  transition: opacity 0.5s ease;
}

.dateHeader {
  position: sticky;
  position: -webkit-sticky;
  top: 8px;
  transform: translateY(0px);
  transition:
    opacity 0.3s ease,
    transform 0.3s ease;
}

.event {
  position: relative;
  display: flex;
  width: 90%;
  cursor: pointer;
  margin-left: 80px;
  word-break: normal;
  text-wrap: wrap;
}

.eventData {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 18px;
  padding-top: 12px;
  border-bottom: 1px solid rgba(51, 62, 83, 0.5);
  text-wrap: balance;
  word-break: normal;
  word-wrap: normal;
  border-bottom: 1px solid var(--dark-color);
}

.eventTitle {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 12px;
  font-family: 'Verdana-Bold', sans-serif;
  line-height: 23px;
}

.eventInfo {
  display: flex;
  gap: 12px;
}

.eventExtra {
  display: flex;
  gap: 12px;
  align-items: center;
}

.eventTime {
  width: 180px;
}

.eventLocation {
  flex: 1;
}

.eventTime,
.eventLocation,
.eventOrg {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Montserrat', sans-serif;
}

.eventIcon {
  height: 18px;
  width: 18px;
  opacity: 0.3;
}

@media (max-width: 912px) {
  .eventsWrapper::before {
    display: none;
  }
  .eventsWrapper::after {
    display: none;
  }
  .eventTitle {
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 6px;
  }

  .eventTime,
  .eventLocation,
  .eventOrg {
    font-size: 14px;
    line-height: 18px;
  }

  .eventOrg {
    margin-bottom: 6px;
  }

  .eventData {
    border-bottom: 1px solid var(--dark-color);
  }

  .event {
    width: 86%;
  }
}

@media (max-width: 540px) {
  .eventsWrapper {
    overflow: auto;
    overflow-x: hidden;
  }

  .eventsWrapper::after {
    box-shadow: inset 0px -42px 32px -32px var(--dark-color);
  }

  .eventInfo {
    flex-direction: column;
    gap: 6px;
  }

  .eventTime,
  .eventLocation,
  .eventOrg {
    font-size: 12px;
    line-height: 14px;
  }

  .eventOrg {
    margin-bottom: 6px;
  }

  .eventData {
    padding-bottom: 6px;
    padding-top: 6px;
  }

  .eventTitle {
    font-size: 14px;
    margin-bottom: 4px;
    width: 72%;
    white-space: normal;
    font-weight: 700;
    line-height: 17px;
    word-break: break-word;
  }

  .dateGroup {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 20px;
  }

  .dateGroup::after {
    position: absolute;
    left: 74px;
  }
}
