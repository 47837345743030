.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  z-index: 100;
  box-shadow: 1px 2px 2px rgba(128, 117, 255, 0.1225);
  overflow-y: scroll;
  max-height: 700px;
}

.cover {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(33, 44, 65, 0.9);
}

@media (max-width: 480px) {
  .content {
    padding-top: 120px;
  }
}
