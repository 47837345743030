.calendar {
  flex: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 48px;
}

@media (max-width: 912px) {
  .calendar {
    overflow: auto;
    background-color: var(--gray-color);
  }
}

@media (max-width: 540px) {
  .calendar {
    padding-left: 26px;
    padding-right: 26px;
  }
}
