.weatherContainer {
  background: #eff7ff;
  box-shadow: -2px -2px 4px 0px rgba(239, 247, 255, 0.25);
  color: var(--dark-color);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  /* max-width: 90vw;  */
  /* overflow: hidden; */
  width: 341px;
}

.header {
  align-items: center;
  background: #fff;
  background-position: center;
  background-size: cover;
  border-radius: 30px 30px 0px 0px;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.header h2 {
  color: var(--dark-background, #212c41);
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1.17px;
  text-transform: uppercase;
}

.headerLocation {
  color: var(--dark-background, #212c41);
  font-family: Verdana;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 17px;
  margin-top: 5px;
}

.currentWeather {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.currentWeatherDescription {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.hourlyWeather {
  width: 302px;
  height: 119px;
  display: flex;
  flex-direction: column;
  border-radius: 18px;
  border: 2px solid #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  flex-shrink: 0;
  padding: 8px;
  margin: 22px;
}

.hourlyList {
  columns: 6;
  column-gap: 8px;
}

.hourlyItem {
  color: var(--dark-background, #212c41);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 100% */
  letter-spacing: -0.8px;
  text-align: center;
  padding: 0px;
  margin-bottom: 10px;
}

.weatherIcon {
  width: 40px;
  height: 40px;
  padding: 0px 0.7px 0.7px 0px;
  filter: contrast(0.72);
  -webkit-filter: contrast(0.72);
}

.dailyWeather {
  border-radius: 18px;
  border: 2px solid #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--dark-background, #212c41);
  font-family: Verdana;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 381px;
  justify-content: space-evenly;
  margin-right: 22px;
  margin-left: 22px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  -ms-border-radius: 18px;
  -o-border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
}

.dailyItem {
  color: var(--dark-background, #212c41);
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 14px;
  font-weight: 300;
  text-wrap: nowrap;
  /* top: -2px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.8px solid lightgray;
}

.dayName {
  width: 36%;
}

.temperatureContainer {
  display: flex;
  align-items: center;
}

.close {
  flex-shrink: 0;
  position: absolute;
  top: 28px;
  right: 20px;
  height: 21px;
  width: 21px;
  cursor: pointer;
}

.currentTemperature {
  color: var(--dark-background, #212c41);
  font-family: Verdana;
  font-size: 96px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-content: center;
  position: relative;
}

h3 {
  color: var(--dark-background, #212c41);
  font-family: Verdana;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.56px;
  text-transform: uppercase;
  padding-top: 15px;
}

.calendar_10day_icon {
  fill: #333e53 !important;
}

.linkToOpenWeather {
  color: var(--dark-background-2, #333e53);
  font-family: Montserrat;
  font-size: 14px;
  font-style: italic;
  font-weight: 300;
  line-height: normal;
  text-decoration-line: underline;
  text-align: center;
}
.linkToOpenWeather::after {
  content: 'Weather provided by OpenWeather';
}

@media (max-width: 912px) {
  .weatherContainer {
    width: 281px;
  }

  .header {
    position: absolute;
    width: 281px;
    top: 0vh;
  }

  .header h2 {
    font-size: 10px;
  }

  .headerLocation {
    font-size: 12px;
  }

  .currentTemperature {
    position: relative;
    top: 102px;
    font-size: 68px;
    margin-bottom: 42px;
    justify-content: center;
  }

  .currentWeather {
    margin-bottom: 54px;
  }

  .currentWeatherDescription {
    font-size: 16px;
  }

  .hourlyWeather {
    width: 238px;
  }

  .hourlyList {
    columns: 6;
    column-gap: 8px;
  }

  .hourlyItem {
    font-size: 12px;
    font-weight: 500;
  }

  .hourlyItem .weatherIcon {
    width: 36px;
    height: 36px;
    position: relative;
    top: 5px;
    right: 5px;
  }


  .dayName {
    width: 42%;
  }

  .linkToOpenWeather {
    font-size: 12px;
  }

  .close {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 540px) {
  .weatherContainer {
    width: 242px;
    height: 600px;
  }

  .header {
    position: relative;
    width: 242px;
    top: 0vh;
    height: 32px;
  }

  .header h2 {
    font-size: 10px;
    padding: 4px;
  }

  .headerLocation {
    font-size: 10px;
  }

  .currentTemperature {
    position: relative;
    top: 66px;
    font-size: 48px;
    margin-bottom: 72px;
  }

  .currentWeather {
    margin-bottom: -40px;
  }

  .currentWeatherDescription {
    font-size: 12px;
    position: relative;
    top: 32px;
    right: 8px;
  }

  .hourlyWeather {
    margin-top: 42px;
    width: 198px;
  }

  .hourlyList {
    column-gap: 46px;
    overflow-x: scroll;
  }

  .hourlyItem {
    font-size: 14px;
  }

  .dailyWeather {
    height: 390px;
    overflow-y: scroll;
  }

  .dayName {
    width: 48%;
    font-size: 12px;
  }

  .temperatureContainer {
    font-size: 10px;
  }

  .linkToOpenWeather {
    font-size: 10px;
  }

  .close {
    width: 14px;
    height: 14px;
    top: 14px;
  }
}
