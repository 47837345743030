.search {
  width: 100%;
  display: flex;
  gap: 8px;
  border: 2px solid var(--white-color);
  border-radius: 8px;
  padding: 10px 12px;
  overflow: auto;
  min-height: 48px;
  background-color: var(--dark-color);
  align-items: center;
}

.search::-webkit-scrollbar {
  display: none;
}

.input {
  width: 100%;
  background-color: transparent;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: var(--white-color);
  border: none;
  outline: 0;
  resize: none;
  font-family: 'Montserrat', sans-serif;
}

.input::-webkit-scrollbar {
  display: none;
}

.input::placeholder {
  font-size: 12px;
  font-weight: 500;
  color: var(--white-color);
  font-family: 'Montserrat', sans-serif;
}

.searchIcon {
  height: 24px;
  width: 24px;
}

.searchButton {
  margin-left: 12px;
  cursor: pointer;
}

.searchButton:hover {
  opacity: 0.75;
}

.searchButton:active {
  opacity: 0.5;
}

.clear {
  background-color: transparent;
  border: none !important;
  cursor: pointer;
  font-weight: 800;
  font-size: 16px;
  height: 18px;
  width: 18px;
}

@media (max-width: 912px) {
  .input {
    line-height: 22px;
  }
}

@media (max-width: 540px) {
  .search {
    border-radius: 6px;
    padding: 8px 10px;
    min-height: 38px;
  }

  .searchIcon {
    position: absolute;
  }

  .input {
    font-size: 14px;
    margin-left: 24px;
  }

  .input::placeholder {
    font-size: 12px;
    line-height: 22px;
    overflow: hidden;
  }
  .searchIcon {
    height: 16px;
    width: 16px;
  }
}
