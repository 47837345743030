.title {
  font-size: 24px;
  line-height: 26px;
  font-weight: 700;
  font-family: 'Verdana-Bold', sans-serif;
}

.description {
  font-size: 20px;
  line-height: 26px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}

.infoBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media (max-width: 912px) {
  .title {
    text-align: center;
  }

  .description{
    text-align: center;
    font-size: 18px;
  }
}

@media (max-width: 540px) {
  .title {
    font-size: 18px;
  }

  .description{
    font-size: 16px;
  }
}